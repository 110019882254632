import React from "react"
import Title from "./Title"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"

const Research = ({
  title,
  longDescription,
  publications,
  recentTalks,
  honorsAndAwards,
}) => {
  const sortedPublication = publications.sort(
    (a, b) => parseInt(b.publicationDate) - parseInt(a.publicationDate)
  )
  return (
    <section className="section">
      <Title title={title} />
      <div className="section-center">
        <p>
          <ReactMarkdown source={longDescription} />
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <h3>Honors and Awards</h3>
        <p>
          <ReactMarkdown source={honorsAndAwards} />
        </p>{" "}
        <br></br>
        <br></br>
        <h3>Recent Talks</h3>
        <p>
          <ReactMarkdown source={recentTalks} />
        </p>{" "}
        <br></br>
        <br></br>
        <h3>Selected Publications</h3>
        {sortedPublication.map(publication => {
          return (
            <p key={publication.id}>
              {publication.file && publication.file.url ? (
                <Link to={publication.file.url}>{publication.title}</Link>
              ) : (
                <b>{publication.title}</b>
              )}
              <br></br>
              {publication.authors}
              <br></br>
              <i>{publication.venue}</i>, {publication.publicationDate}
            </p>
          )
        })}
      </div>
    </section>
  )
}

Research.propTypes = {
  title: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  publications: PropTypes.array.isRequired,
}

export default Research
