import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Research from "../components/Research"
import SEO from "../components/SEO"

const research = ({ data }) => {
  const { datoCmsResearch } = data
  return (
    <Layout>
      <SEO title="Research" description="Research activities" />
      <section className="research-page">
        <Research title="Research" {...datoCmsResearch} />{" "}
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    datoCmsResearch {
      longDescription
      recentTalks
      honorsAndAwards
      publications {
        file {
          url
        }
        title
        authors
        venue
        publicationDate(formatString: "yyyy")
        id
      }
    }
  }
`

export default research
